import vatData from '@/@fake-db/data/vatData.json'
import customer from '@/api/customer/customer'
import createReceipt from '@/api/receipt/createReceipt'
import detailReceipt from '@/api/receipt/detailReceipt'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import { useRouter } from '@core/utils'
import store from '@/store'
import { onMounted, ref } from '@vue/composition-api'
import receipt from '@/api/receipt/createReceipt'
import { required } from '@/@core/utils/validation'
import teamList from '@/api/teamList'

export default function useOrderDetail() {
  const customerData = ref({})
  const receiptData = ref({})
  const discountName = ref('')
  const tax_type = ref(vatData.data)
  const getReceiptLoading = ref(true)
  const orderId = ref('')
  const isPrint = ref(false)
  const isPaysolutions = ref(false)
  const isSelectItem = ref(false)
  const paySolutionsStatus = ref(localStorage.getItem('paySolutions'))
  // data table valiable
  const columns = ref([
    { text: '#', value: 'id', width: 30 },
    { text: i18n.t('code'), value: 'orderdetail_id', width: 100 },
    { text: `${i18n.t('list')}/${i18n.t('service')}`, value: 'orderdetail_name', width: 200 },
    {
      text: i18n.t('amount'),
      value: 'orderdetail_amount',
      align: 'end',
      width: 80,
    },
    {
      text: i18n.t('unit'),
      value: 'checking_id',
      align: 'center',
      width: 80,
    },
    {
      text: `${i18n.t('price')}/${i18n.t('unit')}`,
      value: 'orderdetail_price',
      align: 'center',
      width: 70,
    },
    {
      text: `${i18n.t('discount')}/${i18n.t('unit')}`,
      value: 'orderdetail_discount',
      align: 'end',
      width: 110,
    },
    {
      text: i18n.t('total_price'),
      value: 'orderdetail_total',
      align: 'end',
      width: 100,
    },
  ])

  const cartList = ref([])
  const cartListNew = ref([])
  const cartLoading = ref(false)
  const isRefund = ref(false)
  const dataTableTotal = ref(0)
  const options = ref({})

  const isPayment = ref(false)
  const isPayInstallment = ref(false)
  const isUserConfirm = ref(false)
  const isCancel = ref(false)
  const cancelLoading = ref(false)
  const formCancel = ref(null)
  const comment = ref('')
  const statusPath = ref('')
  const { route } = useRouter()
  const orderPayId = ref('')
  const order_id_pri = ref('')

  const userConfirmStatus = ref(1)
  const isConfirmPatment = ref(false)
  const checkStatusPaySolution = ref(false)
  const processingDeposit = ref(false)
  const teamsAndUserList = ref([])
  const commissionSelect = ref('')
  const order_comment = ref('')

  const cashbackTypeList = ref([
    {
      'value': '0',
      'text': i18n.t('unknown'),
    },
    {
      'value': '1',
      'text': i18n.t('pointFormat'),
    },
    {
      'value': '2',
      'text': i18n.t('couponFormat'),
    },
  ])
  const cashbackSelect = ref('0')


  const { detailReceiptGet } = detailReceipt
  const { customerById } = customer
  const { receiptStatusUpdate, receiptInstallmentGet } = createReceipt

  onMounted(() => {
    getReceipt()
    getTeamList()
  })

  const { teamListGet } = teamList

  const getTeamList = () => {
    teamListGet().then(res => {
      teamsAndUserList.value = res
      commissionSelect.value = res.find(item => item.active_id == 1).teams_id
    })
  }


  const getReceipt = () => {
    cartLoading.value = true
    detailReceiptGet(router.currentRoute.params.id).then(res => {
      cartList.value = res.orderdetail
      order_id_pri.value = res.order_id_pri
      receiptData.value = JSON.parse(JSON.stringify(res))
      discountName.value =
        i18n.locale == 'th' ? tax_type.value[+res.order_tax_id - 1].th : tax_type.value[+res.order_tax_id - 1].en
      options.value.page = 1
      options.value.itemsPerPage = -1
      getCustomer(res.customer_id_pri)

    })
  }

  const checkPaySulotions = () => {
    receiptInstallmentGet(router.currentRoute.params.id).then(res => {
      if (res.orderpay_period == 1 && paySolutionsStatus.value == '1') {
        isConfirmPatment.value = true
        checkStatusPaySolution.value = true
      }
    })
  }

  const getCustomer = id => {
    customerById(id).then(res => {
      customerData.value = res
      cartLoading.value = false
      checkPaySulotions()
    })
  }

  const paysolutions = () => {
    processingDeposit.value = true
    receipt.receiptPay({
      order_id_pri: router.currentRoute.params.id,
      order_pay_id: 3,
      order_comment: order_comment.value,
      opd_id: receiptData.value.opd_id,
      order_point_id: null,
      check_date_id: 2,
      order_pointsave: null,
      shop_bank_id: null,
      teams_id: commissionSelect.value,
      orderpay_datetime: receiptData.value.order_create,
      cashback_type_id: cashbackSelect.value,
      paysolutions_status_id: '1',
      status_check_deposit: null,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_data_successfully',
          color: 'success',
        })
        store.commit('app/setStatusUpdateHeader')
        getReceipt()
        isPaysolutions.value = false
        processingDeposit.value = false
      } else {
        processingDeposit.value = false
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
      }
    })
  }

  const confirmCancel = () => {
    const isFormValid = formCancel.value.validate()
    if (!isFormValid) return
    cancelLoading.value = true

    receiptStatusUpdate({
      order_id_pri: router.currentRoute.params.id,
      order_comment: comment.value,
      lang: i18n.locale,
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      store.commit('app/setStatusUpdateHeader')
      isCancel.value = false
      getReceipt()
      cancelLoading.value = false
    })
  }

  return {
    customerData,
    receiptData,
    discountName,
    tax_type,
    getReceiptLoading,
    getReceipt,
    getCustomer,
    isPayment,
    isUserConfirm,
    userConfirmStatus,
    comment,
    columns,
    dataTableTotal,
    options,
    cartLoading,
    formCancel,
    paySolutionsStatus,
    cancelLoading,
    cartList,
    isCancel,
    isRefund,
    confirmCancel,
    statusPath,
    isPayInstallment,
    orderId,
    isPrint,
    orderPayId,
    cartListNew,
    paysolutions,
    cashbackTypeList,
    cashbackSelect,
    order_id_pri,
    order_comment,
    isSelectItem,
    isPaysolutions,
    required,
    checkStatusPaySolution,
    isConfirmPatment,
    teamsAndUserList,
    commissionSelect,
    processingDeposit,
  }
}
