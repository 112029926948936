<template>
  <v-dialog v-model='isPayment' persistent max-width='450'>
    <v-card>
      <v-card-title class='text-subtitle-1'>
        <v-radio-group v-model='typePayment' hide-details class='my-0 pb-0' row>
          <v-radio :label="$t('full')" value='1'></v-radio>
          <v-radio :label="$t('installment')" value='2'></v-radio>
        </v-radio-group>
        <v-spacer></v-spacer>
        <span class='primary--text text-decoration-underline'>{{ totalPay | formatPrice }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols='12'>
            <v-row justify='center' align='center'>
              <v-col cols='5'>
                {{ $t('amount_baht') }}
                <span v-if='typePayment == 2' class='primary--text'>({{ $t('period') }} 1) </span>
              </v-col>
              <v-col cols='7'>
                <v-text-field
                  v-model='payAmountLocal'
                  outlined
                  hide-details
                  :disabled="typePayment == '1'"
                  reverse
                  dense
                  type='number'
                >
                  <template v-slot:prepend>
                    <v-icon color='primary'
                            @click='payAmountLocal > 0 ? payAmountLocal-- : (payAmountLocal = 0)'>
                      {{ icons.mdiMinus }}
                    </v-icon>
                  </template>
                  <template v-slot:append-outer>
                    <v-icon color='primary' @click='payAmountLocal++'>
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align='center' justify='center' v-if='!coupon_id || coupon_id==`null`'>
              <v-col cols='5'>
                {{ $t('cashback') }}
              </v-col>
              <v-col cols='7'>
                <v-select
                  v-model='cashbackSelect'
                  :items='cashbackTypeList'
                  dense
                  hide-details
                  outlined
                  item-text='text'
                  item-value='value'
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="payId == '1' || (payId == '4' && isLastDate)" align='center'
                   justify='center'>
              <v-col cols='5'>
                <span>
                  {{ $t('separate payment date') }}
                </span>
              </v-col>
              <v-col cols='7' class='py-0 my-0'>
                <v-switch v-model='statusCheckDeposit' :true-value='1' :false-value='0'></v-switch>
              </v-col>
              <v-col cols='5' class='py-0 my-0'>
                <span> {{ $t('selectDatePatyment') }} <br /> </span>
                <span v-if="changeDateType == '2'">
                  {{ dateCreate | sumdate }}
                </span>
                <span v-if="changeDateType == '1'">
                  {{ dateNow | sumdate }}
                </span>
              </v-col>
              <v-col cols='7'>
                <v-radio-group
                  hide-details
                  v-model='changeDateType'
                  :disabled='statusCheckDeposit == 1'
                  dense
                  class='py-0 my-0'
                >
                  <v-radio :label="$t('useCurrentDate')" value='1'></v-radio>
                  <v-radio :label="$t('useCreateReceipt')" value='2'></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row align='center' justify='center'>
              <v-col cols='5'>
                <small v-if='statusPoint && +totalPay - +orderPointsave > 0' class='error--text'
                >{{ $t('pointChage') }} {{ +totalPay - +orderPointsave }}<br
                /></small>
                {{ $t('channel_pay') }}
              </v-col>
              <v-col cols='7'>
                <v-select
                  v-model='channelPaySelect'
                  :items='channelPayList'
                  dense
                  hide-details
                  outlined
                  item-text='shop_bank_name'
                  item-value='shop_bank_id'
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row align='center' justify='center'>
              <v-col cols='5'>
                {{ $t('user_is_list') }} <br />
                {{ $t('staff_team') }}
              </v-col>
              <v-col cols='7'>
                <v-select
                  v-model='commissionSelect'
                  :items='teamsAndUserList'
                  dense
                  hide-details
                  outlined
                  item-text='teams_name'
                  item-value='teams_id'
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="typePayment == '1'" align='center' justify='center'>
              <v-col cols='5'>
                <v-checkbox v-model='statusPoint' :label="$t('redeem_points')"></v-checkbox>
              </v-col>
              <v-col cols='7'>
                <v-text-field
                  v-model='orderPointsave'
                  outlined
                  hide-details
                  :disabled='!statusPoint'
                  reverse
                  dense
                  type='number'
                >
                  <template v-slot:label>
                    <span class='error--text'> {{ $t('current_points')
                      }} : {{ customerData.customer_point }} </span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align='center' justify='center'>
              <v-col cols='3'>
                {{ $t('note') }}
              </v-col>
              <v-col cols='9'>
                <v-textarea
                  v-model='order_comment'
                  :rows='3'
                  hide-details
                  :placeholder="$t('note')"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='processingDeposit'
          :disabled="
            payAmountLocal < 0 ||
            payAmountLocal > totalPay ||
            processingDeposit ||
            +orderPointsave > +customerData.customer_point ||
            (payAmountLocal >= totalPay && typePayment == '2')
          "
          @click='pay'
        >
          {{ $t('confirm') }}
        </v-btn>
        <v-btn
          color='secondary'
          outlined
          :loading='processingDeposit'
          :disabled='processingDeposit'
          @click="$emit('update:is-payment', false)"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiMinus, mdiPlus } from '@mdi/js'
import receipt from '@/api/receipt/createReceipt'
import { formatPrice, sumdate } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import router from '@/router'
import document from '@/api/systemSetting/document'
import teamList from '@/api/teamList'
import shopBank from '@/api/shopbank'

export default {
  filters: {
    formatPrice,
    sumdate,
  },
  model: {
    prop: 'isPayment',
    event: 'update:is-payment',
  },
  props: {
    isPayment: {
      type: Boolean,
      default: false,
    },
    customerData: {
      type: Object,
      default: () => ({}),
    },
    totalPay: {
      type: Number,
      default: 0,
    },
    payAmount: {
      type: Number,
      default: 0,
    },
    dateCreate: {
      type: String,
      default: '',
    },
    depositId: {
      type: String,
      default: '',
    },
    payId: {
      type: String,
      default: '',
    },
    orderpayStatusId: {
      type: String,
      default: '',
    },
    coupon_id: {
      type: String,
      default: '',
    },

  }
  ,

  setup(props, { emit }) {

    const moment = require('moment')
    const typePayment = ref('1')
    const channelPaySelect = ref(0)
    const commissionSelect = ref(0)
    const channelPayList = ref([])
    const teamsAndUserList = ref([])
    const statusPoint = ref(false)
    const orderPointsave = ref(0)
    const payAmountLocal = ref(0)
    const order_comment = ref('')
    const statusCheckDeposit = ref(1)
    const changeDateType = ref('2')
    const processingDeposit = ref(false)
    const dateNow = ref(moment(new Date()).format('YYYY-MM-DD'))
    const isLastDate = ref(false)

    const cashbackTypeList = ref([
      {
        'value': '0',
        'text': i18n.t('unknown'),
      },
      {
        'value': '1',
        'text': i18n.t('pointFormat'),
      },
      {
        'value': '2',
        'text': i18n.t('couponFormat'),
      },
    ])
    const cashbackSelect = ref('0')


    const { receiptPay, receiptDeposit } = receipt
    const { shopbankList } = shopBank
    const { teamListGet } = teamList

    const getDocsSettings = () => {
      document.documentSetting().then(res => {
        cashbackSelect.value = res.cashback_id
      })
    }

    const getTeamList = () => {
      teamListGet().then(res => {
        teamsAndUserList.value = res
        commissionSelect.value = res.find(item => item.active_id == 1).teams_id
      })
    }

    const getPaymentChanel = () => {
      shopbankList().then(res => {
        channelPayList.value = res
        channelPaySelect.value = res[0].shop_bank_id
      })
    }


    const pay = () => {
      processingDeposit.value = true
      receiptPay({
        order_comment: order_comment.value,
        order_id_pri: router.currentRoute.params.id,
        order_pay_id: typePayment.value == '1' ? 2 : 3,
        order_point_id: !statusPoint.value ? 1 : 2,
        order_pointsave: orderPointsave.value ? orderPointsave.value : '',
        shop_bank_id: channelPaySelect.value,
        teams_id: commissionSelect.value,
        order_draft_id: props.depositId == '1' ? 0 : 1,
        orderpay_datetime: props.dateCreate,
        check_date_id: changeDateType.value,
        cashback_type_id: cashbackSelect.value,
        status_check_deposit: String(statusCheckDeposit.value),
        lang: i18n.locale,
      }).then(res => {
        if (res.response) {
          if (typePayment.value == '2') {
            depositPayment(router.currentRoute.params.id)
          } else {
            store.commit('app/ALERT', {
              message: 'add_data_successfully',
              color: 'success',
            })
            store.commit('app/setStatusUpdateHeader')
            emit('onUpdate')
            emit('update:is-payment', false)
          }
        } else {
          store.commit('app/ALERT', {
            message: 'error',
            color: 'error',
          })
          processingDeposit.value = false
          emit('update:is-payment', false)
        }
      })
    }

    const depositPayment = id => {
      receiptDeposit({
        order_id_pri: id,
        orderpay_current: payAmountLocal.value,
        shop_bank_id: channelPaySelect.value,
        orderpay_comment: order_comment.value,
        orderpay_datetime: props.dateCreate,
        check_date_id: changeDateType.value,
        status_check_deposit: String(statusCheckDeposit.value),
      }).then(res => {
        if (res.response) {
          store.commit('app/ALERT', {
            message: 'add_data_successfully',
            color: 'success',
          })
          store.commit('app/setStatusUpdateHeader')
          emit('onUpdate')
          emit('update:is-payment', false)
        } else {
          store.commit('app/ALERT', {
            message: 'error',
            color: 'error',
          })
          processingDeposit.value = false
          emit('update:is-payment', false)
        }
      })
    }

    watch(statusPoint, value => {
      if (value) {
        if (+props.customerData.customer_point <= +payAmountLocal.value) {
          orderPointsave.value = +props.customerData.customer_point
        } else {
          orderPointsave.value = +payAmountLocal.value
        }
      } else {
        orderPointsave.value = 0
      }
    })

    watch(
      () => props.depositId,
      value => {
        if (value == '1') {

          changeDateType.value = '1'
        } else {
          changeDateType.value = '2'
        }
      },
    )

    watch(
      () => props.isPayment,
      value => {
        if (value) {
          getDocsSettings()
          getTeamList()
          getPaymentChanel()
          payAmountLocal.value = props.payAmount
        }
      },
    )

    watch(
      () => props.dateCreate,
      value => {
        moment(props.dateCreate).isBefore(moment(dateNow.value).format('YYYY-MM-DD'))
          ? (isLastDate.value = true)
          : (isLastDate.value = false)
      },
    )

    watch(typePayment, () => {
      payAmountLocal.value = +props.totalPay
    })

    return {
      cashbackSelect,
      cashbackTypeList,
      typePayment,
      channelPaySelect,
      commissionSelect,
      statusPoint,
      orderPointsave,
      order_comment,
      channelPayList,
      teamsAndUserList,
      processingDeposit,
      payAmountLocal,
      dateNow,
      changeDateType,
      isLastDate,
      statusCheckDeposit,
      pay,
      icons: {
        mdiMinus,
        mdiPlus,
      },
    }
  }
  ,
}
</script>
