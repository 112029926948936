<template>
  <v-dialog v-model="isSelectItem" max-width="1000" persistent>
    <v-card>
      <v-card-title>
        {{ $t('edit_price') }}
        <v-spacer />
        <span class="error--text"> {{ $t('total_price') }} : {{ total | formatPrice }} {{ $t('THB') }} </span>
      </v-card-title>
      <v-data-table
        :headers="columnsEdit"
        :items="cartListLocal"
        :options.sync="options"
        mobile-breakpoint="0"
        fixed-header
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.checking_id`]="{ item }">
          {{ item.checking_id || item.opdchecking_id ? $t('times') : item.orderdetail_unit }}
        </template>

        <template v-slot:[`item.orderdetail_price`]="{ item }">
          <v-text-field
            v-model="item.orderdetail_price"
            :rules="[required]"
            dense
            class="my-1"
            outlined
            hide-details="auto"
            :disabled="item.orderdetail_type_id == 3"
            @input="checkDiscountPrice(item.orderdetail_price, item.orderdetail_discount)"
          >
          </v-text-field>
        </template>
        <template v-slot:[`item.orderdetail_discount`]="{ item }">
          <v-text-field
            v-model="item.orderdetail_discount"
            outlined
            dense
            class="my-1"
            hide-details="auto"
            :rules="[required]"
            disabled
          >
          </v-text-field>
        </template>

        <template v-slot:[`item.orderdetail_total`]="{ item }">
          {{
            item.orderdetail_unit == 'วัน' || item.orderdetail_unit == 'ครั้ง/คอร์ส'
              ? item.orderdetail_price
              : ((item.orderdetail_price - item.orderdetail_discount) * item.orderdetail_amount) | formatPrice
          }}
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions class="mt-1">
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="overPrice" @click="receiptEdit">
          {{ $t('updatePrice') }}
        </v-btn>
        <v-btn color="secondary" outlined @click="$emit('update:isSelectItem', false)">
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import { required } from '@/@core/utils/validation'
import { formatPrice } from '@/plugins/filters'
import createReceipt from '@/api/receipt/createReceipt'
import store from '@/store'
import router from '@/router'
export default {
  model: {
    prop: 'isSelectItem',
    event: 'update:isSelectItem',
  },
  props: {
    isSelectItem: {
      type: Boolean,
      default: false,
    },
    cartList: {
      type: Array,
      default: () => [],
    },
    discount: {
      type: String,
      default: '',
    },
  },
  filters: {
    formatPrice,
  },
  setup(props, { emit }) {
    const columnsEdit = ref([
      { text: '#', value: 'id', width: 30 },
      { text: i18n.t('code'), value: 'orderdetail_id', width: 100 },
      { text: `${i18n.t('list')}/${i18n.t('service')}`, value: 'orderdetail_name', width: 200 },
      {
        text: i18n.t('amount'),
        value: 'orderdetail_amount',
        align: 'end',
        width: 80,
      },
      {
        text: i18n.t('unit'),
        value: 'checking_id',
        align: 'center',
        width: 80,
      },
      {
        text: `${i18n.t('price')}/${i18n.t('unit')}`,
        value: 'orderdetail_price',
        align: 'center',
        width: 100,
      },

      {
        text: `${i18n.t('discount')}/${i18n.t('unit')}`,
        value: 'orderdetail_discount',
        align: 'end',
        width: 110,
      },

      {
        text: i18n.t('total_price'),
        value: 'orderdetail_total',
        align: 'end',
        width: 100,
      },
    ])
    const options = ref({})
    const cartListLocal = ref([])
    const overPrice = ref(false)
    const loading = ref(false)
    const total = ref(0)

    const checkDiscountPrice = (price, discount) => {
      if (parseFloat(price) < parseFloat(discount) && parseFloat(discount) != 0) {
        overPrice.value = true
      } else {
        overPrice.value = false
      }
      totalOrder()
    }

    const totalOrder = () => {
      total.value = 0
      cartListLocal.value.forEach(item => {
        console.log('item : ', item.orderdetail_unit)
        total.value = total.value +=
          item.orderdetail_unit == 'วัน' || item.orderdetail_unit == 'ครั้ง/คอร์ส'
            ? parseFloat(item.orderdetail_price)
            : (parseFloat(item.orderdetail_price) - parseFloat(item.orderdetail_discount)) *
              parseFloat(item.orderdetail_amount)
        if (
          total.value < parseFloat(props.discount) ||
          parseFloat(item.orderdetail_price) < parseFloat(item.orderdetail_discount)
        ) {
          overPrice.value = true
        } else {
          overPrice.value = false
          if (!parseFloat(total.value)) {
            overPrice.value = true
          }
        }
      })
    }
    const receiptEdit = () => {
      loading.value = true
      const idCratList = cartListLocal.value.map(item => item.orderdetail_id_pri)
      const priceList = cartListLocal.value.map(item => item.orderdetail_price)
      createReceipt
        .receiptUpdate({
          order_id_pri: router.currentRoute.params.id,
          orderdetail_id_pri: idCratList,
          orderdetail_price: priceList,
        })
        .then(res => {
          store.commit('app/ALERT', {
            message: res.message,
            color: res.response ? 'success' : 'error',
          })
          emit('updatePice')
          emit('update:isSelectItem', false)
          loading.value = false
        })
        .catch(err => {
          loading.value = false
          console.log('error : ', err)
        })
    }

    watch(
      () => props.isSelectItem,
      value => {
        if (value) {
          cartListLocal.value = JSON.parse(JSON.stringify(props.cartList))
          totalOrder()
        }
      },
    )

    return {
      columnsEdit,
      options,
      overPrice,
      required,
      cartListLocal,
      total,
      receiptEdit,
      checkDiscountPrice,
    }
  },
}
</script>
