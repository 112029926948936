<template>
  <v-dialog
    v-model="isRefund"
    max-width="900"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t("confirm_refund_course") }} {{ $t("receipt_number") }}
        {{ refundData.order_id }}
      </v-card-title>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                {{ $t("receipt_list") }}
              </th>
              <th>
                {{ $t("amount") }}
              </th>
              <th class="text-right">
                {{ $t("price") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in refundData.detail"
              :key="index"
            >
              <td
                class=""
                width="60%"
              >
                {{ item.orderdetail_name }} ({{ item.orderdetail_id }})
              </td>
              <td
                class=""
                width="60%"
              >
                {{ item.orderdetail_amount }} {{ item.orderdetail_unit }}
              </td>
              <td
                class="text-right "
                width="60%"
              >
                {{ item.orderdetail_total | formatPrice }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table dense>
        <thead>
          <tr>
            <th>
              {{ $t("list_of_courses") }}
            </th>
            <th>
              {{ $t("cost_of_drug_equipment_used") }}
            </th>
            <th class="text-right">
              {{ $t("amount") }}
            </th>
            <th class="text-right">
              {{ $t("price") }}
            </th>
          </tr>
        </thead>
        <tbody
          v-for="(item, index) in refundData.serving"
          :key="index"
        >
          <tr>
            <td class="font-weight-bold">
              {{ item.serving_name }}
            </td>
            <td class="font-weight-bold">
              {{ $t("doctor_staff_free") }}
            </td>
            <td class="text-right"></td>
            <td class="text-right">
              {{ item.serving_cost | formatPrice }}
            </td>
          </tr>
          <tr
            v-for="(sub, index) in item.servingdrug"
            :key="index"
          >
            <td class=""></td>
            <td class="">
              {{ sub.servingdrug_drug_name }} ({{ sub.servingdrug_drug_id }})
            </td>
            <td class=" text-right">
              {{ sub.servingdrug_drug_amount }}
              {{ sub.servingdrug_drug_unit }}
            </td>
            <td class=" text-right">
              {{ sub.servingdrug_drug_total }}
            </td>
          </tr>
          <tr>
            <td
              colspan="3"
              class="text-right font-weight-bold"
            >
              {{ $t("total") }}
            </td>
            <td class="text-right font-weight-bold">
              {{ refundData.sum_cost | formatPrice }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table dense>
        <thead>
          <tr>
            <th
              class="text-right"
            >
              {{ $t("total_receipt") }}
            </th>
            <th
              class="text-right"
            >
              {{ $t("total_payment") }}
            </th>
            <th
              class="text-right"
            >
              {{ $t("total_cost") }}
            </th>
            <th
              class="text-right"
            >
              {{ $t("refund_amount") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class=" text-right">
              {{ refundData.order_totalpay | formatPrice }}
            </td>
            <td class=" text-right">
              {{ refundData.orderpay_all | formatPrice }}
            </td>
            <td class=" text-right">
              {{ refundData.sum_cost | formatPrice }}
            </td>
            <td class=" text-right font-weight-bold ">
              <span class="primary--text">
                {{ refundData.sum_refund | formatPrice }}
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table dense>
        <thead>
          <tr>
            <th>
              {{ $t("select_the_wallet") }}
            </th>
            <th>
              {{ $t("refund_amount") }}
            </th>
            <th class=" text-right">
              {{ $t("cost") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="d-flex">
              <v-select
                v-model.trim="shopbankSelect"
                :items="shopbankList"
                item-text="shop_bank_name"
                item-value="shop_bank_id"
                class="me-2 my-2"
                hide-details
                dense
                outlined
              ></v-select>
            </td>
            <td>
              <v-text-field
                v-model.trim="order_refund_amount"
                class="my-2"
                hide-details
                type="number"
                dense
                outlined
              ></v-text-field>
            </td>
            <td class="text-right">
              <span class="font-weight-bold error--text">
                {{ (refundData.sum_refund - order_refund_amount) | formatPrice }}
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="refundData.sum_refund - order_refund_amount < 0 ||loading"
          color="primary"
          :loading="loading"
          @click="comfirmRefund()"
        >
          {{ $t("save") }}
        </v-btn>
        <v-btn
          outlined
          color="secondary"
          @click="$emit('update:is-refund',false)"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { formatPrice } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import shopBank from '@/api/shopbank'
import router from '@/router'
import receipt from '@/api/receipt/receipt'
import store from '@/store'

export default {
  filters: {
    formatPrice,
  },
  model: {
    prop: 'isRefund',
    event: 'update:is-refund',
  },
  props: {
    isRefund: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const receiptData = ref({})
    const refundData = ref({})
    const shopbankList = ref([])
    const shopbankSelect = ref('')
    const order_refund_amount = ref(0)
    const loading = ref(false)

    const { shopbankList: shopbankListGet } = shopBank

    const { receiptRefundGet, receiptRefund } = receipt

    const getShopBank = () => {
      shopbankListGet().then(res => {
        shopbankList.value = res
        shopbankSelect.value = res[0].shop_bank_id
      })
    }

    const comfirmRefund = () => {
      loading.value = true
      receiptRefund({
        order_id_pri: router.currentRoute.params.id,
        shop_bank_id: shopbankSelect.value,
        order_refund_amount: order_refund_amount.value,
        lang: i18n.locale,
      }).then(res => {
        if (res.response) {
          store.commit('app/ALERT', {
            message: 'save_comfirm_refund_success',
            color: 'success',
          })
          store.commit('app/setStatusUpdateHeader')
          emit('onUpdate')
          emit('update:is-refund', false)
        } else {
          store.commit('app/ALERT', {
            message: res.message,
            color: 'error',
          })
        }
        loading.value = false
      })
    }

    const getDataRefund = () => {
      receiptRefundGet(router.currentRoute.params.id).then(res => {
        refundData.value = res
        order_refund_amount.value = +res.sum_refund
      })
    }

    watch(() => props.isRefund, value => {
      if (value) {
        getDataRefund()
        getShopBank()
      }
    })

    return {
      loading,
      receiptData,
      refundData,
      order_refund_amount,
      comfirmRefund,
      shopbankList,
      shopbankSelect,
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
