<template>
  <div>
    <v-row>
      <v-col cols='12' md='8'>
        <v-card :loading='cartLoading'>
          <v-card-title class='py-1 pr-0 pl-1'>
            <router-link class='font-weight-medium text-decoration-none'
                         :to="{ name: 'Receipt-list' }">
              <v-tooltip color='#212121' top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-btn color='primary' v-bind='attrs' icon v-on='on'>
                    <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('back') }}</span>
              </v-tooltip>
            </router-link>
            {{ $t('Order_detail') }} - {{ receiptData.order_id }}
            <span v-if="receiptData.status_id == '2'" class='ml-1 error--text'>({{ $t('cancel')
              }})</span>
            <v-spacer></v-spacer>
            <PopupCustomer v-if='customerData.customer_id_pri' :customer-data='customerData' />
          </v-card-title>
          <v-data-table
            :headers='columns'
            :items='cartList'
            :options.sync='options'
            :loading='cartLoading'
            mobile-breakpoint='0'
            dense
            fixed-header
            height='654'
            disable-sort
            hide-default-footer
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >

            <template v-slot:[`item.id`]='{ index }'>
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.orderdetail_name`]='{ item }'>
              <div v-if='item.orderdetail_name_set'>
                <v-chip
                  :color='item.drug_id_pri?`primary`:item.checking_id?`success`:`info`'
                  :class=" item.drug_id_pri?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:item.checking_id?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
                  small
                >
                  <span>
                    {{ item.drug_id_pri ? $t('setDrug') : item.checking_id ? $t('checkingSet') : $t('courseSet')
                    }}
                  </span>
                </v-chip>
                {{ item.orderdetail_name }}
              </div>
              <span v-else>
                {{ item.orderdetail_name }}
              </span>
            </template>
            <template v-slot:[`item.checking_id`]='{ item }'>
              {{ item.checking_id || item.opdchecking_id ? $t('times') : item.orderdetail_unit }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols='12' md='4'>
        <v-card height='700' :loading='cartLoading'>
          <v-card-title class='py-1'>
            <ConverseDate :date='receiptData.order_create' />
            <v-spacer></v-spacer>

            <v-btn
              outlined
              color='warning'
              v-if='receiptData.edit_orderdetail == 1'
              class='mr-1 px-2'
              @click='
                userConfirmStatus = 3
                isUserConfirm = true
              '
            >
              <v-icon small>{{ icons.mdiPencilOutline }}</v-icon>
              <span>{{ $t('edit_price') }}</span>
            </v-btn>
            <v-btn
              outlined
              class='px-2'
              :to="{
                name: 'EditReceipt',
                params: {
                  id: $router.currentRoute.params.id,
                },
              }"
              :disabled='receiptData.edit_order != 1'
              color='warning'
            >
              <v-icon small>{{ icons.mdiPencilOutline }}</v-icon>
              <span>{{ $t('edit_receipt') }}</span>
            </v-btn>
          </v-card-title>
          <v-divider class='mb-2'></v-divider>
          <v-row class='px-6' align='center' justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('total_price') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              {{ receiptData.order_price | formatPrice }} {{ receiptData.currency }}
            </v-col>
          </v-row>
          <v-divider class='my-2'></v-divider>
          <v-row class='px-6' align='center' justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('discount') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              {{ receiptData.order_save | formatPrice }}
              {{ receiptData.currency }}
            </v-col>
          </v-row>
          <v-divider class='my-2' v-if='receiptData.coupon_id'></v-divider>
          <v-row class='px-6' align='center' justify='center' v-if='receiptData.coupon_id'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('cashbackValue') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              {{ receiptData.order_coupon || 0 |formatPrice }} {{ receiptData.currency }}
            </v-col>
          </v-row>
          <v-divider class='my-2'></v-divider>
          <v-row class='px-6' align='center' justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('after_discount') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              <span v-if='receiptData.order_befor_tax'>
                {{ receiptData.order_befor_tax | formatPrice }}
                {{ receiptData.currency }}</span
              >
              <span v-else>0.00 {{ receiptData.currency }}</span>
            </v-col>
          </v-row>
          <v-divider class='my-2'></v-divider>
          <v-row class='px-6' align='center' justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('tax_type') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              {{ discountName }}
            </v-col>
          </v-row>
          <v-divider class='my-2'></v-divider>
          <v-row class='px-6' align='center' justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('vat') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              {{ receiptData.order_tax | formatPrice }}
              {{ receiptData.currency }}
            </v-col>
          </v-row>
          <v-divider class='my-2'></v-divider>
          <v-row class='px-6' align='center' justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('total_amount') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              <span v-if='receiptData.order_total'>
                {{ receiptData.order_total | formatPrice }}
                {{ receiptData.currency }}</span
              >
              <span v-else>0.00 {{ receiptData.currency }}</span>
            </v-col>
          </v-row>
          <v-divider v-if="receiptData.order_deposit_id == '1'" class='my-2'></v-divider>
          <v-row v-if="receiptData.order_deposit_id == '1'" class='px-6' align='center'
                 justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('deposit') }}
              <span class='primary--text'>{{ receiptData.order_deposit_bank_name }}</span>
            </v-col>
            <v-col cols='6' class='text-right'>
              {{ receiptData.order_deposit_amount | formatPrice }}
              {{ receiptData.currency }}
            </v-col>
          </v-row>
          <v-divider v-if="receiptData.order_point_id == '2'" class='my-2'></v-divider>
          <v-row v-if="receiptData.order_point_id == '2'" class='px-6' align='center'
                 justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('use_points') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              {{ receiptData.order_pointsave | formatPrice }}
              {{ $t('point') }}
            </v-col>
          </v-row>
          <v-divider class='my-2'></v-divider>
          <v-row class='px-6' align='center' justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('total_paid') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              <u class='error--text'>
                {{ receiptData.order_totalpay | formatPrice }}
                {{ receiptData.currency }}
              </u>
            </v-col>
          </v-row>
          <v-divider v-if="receiptData.orderpay_status_id == '2' && receiptData.order_pay_id == '3'"
                     class='my-2'>
          </v-divider>
          <v-row
            v-if="receiptData.orderpay_status_id == '2' && receiptData.order_pay_id == '3'"
            class='px-6'
            align='center'
            justify='center'
          >
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('total_payment') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              {{ receiptData.orderpay_all | formatPrice }}
              {{ receiptData.currency }}
            </v-col>
          </v-row>
          <v-divider v-if="receiptData.orderpay_status_id == '2' && receiptData.order_pay_id == '3'"
                     class='my-2'>
          </v-divider>
          <v-row
            v-if="receiptData.orderpay_status_id == '2' && receiptData.order_pay_id == '3'"
            class='px-6'
            align='center'
            justify='center'
          >
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('outstanding_balance') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              <u>
                {{
                  (+receiptData.order_totalpay + +receiptData.order_deposit_amount - +receiptData.orderpay_all)
                    | formatPrice
                }}
                {{ receiptData.currency }}
              </u>
            </v-col>
          </v-row>
          <v-divider class='my-2'></v-divider>
          <v-row v-if='receiptData.order_comment' class='px-6' align='center' justify='center'>
            <v-col cols='6' class='font-weight-semibold'>
              {{ $t('note') }}
            </v-col>
            <v-col cols='6' class='text-right'>
              {{ receiptData.order_comment }}
            </v-col>
          </v-row>
          <v-divider v-if='receiptData.order_comment' class='my-2'></v-divider>
          <v-row no-gutters class='px-3'>
            <v-col v-if='receiptData.order_refund_status_id == 1' cols='12' class='py-1 px-1'
                   xl='6'>
              <v-btn
                outlined
                block
                color='info'
                :loading='cartLoading'
                :disabled='cartLoading'
                @click='
                  userConfirmStatus = 2
                  isUserConfirm = true
                '
              >
                {{ $t('refund') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="receiptData.order_pay_id == 1 && receiptData.order_status_id != '2'"
              cols='12'
              class='py-1 px-1'
              xl='6'
            >
              <v-btn :loading='cartLoading' :disabled='cartLoading' color='primary' block
                     @click='isPayment = true'>
                {{ $t('pay_for_service') }}
              </v-btn>
            </v-col>

            <v-col v-if='receiptData.add_pay == 1 && !checkStatusPaySolution' cols='12' xl='6'
                   class='py-1 px-1'>
              <v-btn
                :loading='cartLoading'
                :disabled='cartLoading'
                block
                color='primary'
                @click='isPayInstallment = true'
              >
                {{ $t('installment_payment') }}
              </v-btn>
            </v-col>

            <v-col v-if='receiptData.print_invoice == 1' cols='12' class='py-1 px-1' xl='6'>
              <v-btn
                block
                outlined
                :loading='cartLoading'
                :disabled='cartLoading'
                color='success'
                @click="
                  isPrint = true
                  orderId = receiptData.order_id_pri
                  statusPath = 'invoice'
                "
              >
                {{ $t('print_invoice') }}
              </v-btn>
            </v-col>

            <v-col v-if='receiptData.print_order_deposit == 1' cols='12' class='py-1 px-1' xl='6'>
              <v-btn
                block
                outlined
                :loading='cartLoading'
                :disabled='cartLoading'
                color='success'
                @click="
                  isPrint = true
                  orderId = receiptData.order_id_pri
                  statusPath = 'deposit'
                "
              >
                {{ $t('print_deposit_receipt') }}
              </v-btn>
            </v-col>
            <v-col v-if='receiptData.print_order == 1' cols='12' xl='6' class='py-1 px-1'>
              <v-btn
                block
                outlined
                :loading='cartLoading'
                :disabled='cartLoading'
                color='success'
                @click="
                  isPrint = true
                  orderId = receiptData.order_id_pri
                  statusPath = 'receipt'
                "
              >
                {{ $t('print_receipt') }}
              </v-btn>
            </v-col>
            <v-col
              class='py-1 px-1'
              v-if='receiptData.add_pay == 1 && checkStatusPaySolution'
              cols='12'
            >
              <v-btn
                :loading='cartLoading'
                v-if="paySolutionsStatus == '1'"
                block
                :disabled='cartLoading'
                class='white--text'
                color='#002cc9'
                @click='isConfirmPatment = true'
              >
                {{ $t('payWithPaySulotion') }}
              </v-btn>
              <!-- <v-btn color="info" block @click="isConfirmPatment = true">{{ $t('payWithPaySulotion') }}</v-btn> -->
            </v-col>
            <v-col
              v-if="receiptData.order_pay_id == 1 && receiptData.order_status_id != '2'"
              cols='12'
              class='py-1 px-1'
            >
              <v-btn
                :loading='cartLoading'
                block
                v-if="paySolutionsStatus == '1'"
                :disabled='cartLoading'
                class='white--text'
                color='#002cc9'
                @click='isPaysolutions = true'
              >
                {{ $t('payWithPaySulotion') }}
              </v-btn>
            </v-col>
            <v-col cols='12'
                   :xl="(receiptData.order_pay_id == 1 && receiptData.order_status_id != '2') || checkStatusPaySolution ?'12':'6'"
                   class='py-1 px-1'>
              <v-btn
                outlined
                block
                :loading='cartLoading'
                :disabled="cartLoading || receiptData.cancel_order == '0'"
                color='error'
                @click="
                  comment = ''
                  userConfirmStatus = 1
                  isUserConfirm = true
                "
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span class='font-weight-semibold text-sm pt-5 error--text px-2'>
              **{{ $t('user_is_list') }} : {{ receiptData.user_fullname }}**
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <Payment
      v-model='isPayment'
      :customer-data='customerData'
      :total-pay='+receiptData.order_totalpay'
      :date-create='receiptData.order_create'
      :pay-amount='+receiptData.order_totalpay'
      :pay-id='String(receiptData.pay_id)'
      :orderpay-status-id='String(receiptData.orderpay_status_id)'
      :deposit-id='String(receiptData.order_deposit_id)'
      :coupon_id='String(receiptData.coupon_id)'
      @onUpdate='getReceipt'
    />
    <PayInstllment v-model='isPayInstallment' @onUpdate='getReceipt' />

    <UserConfirm
      v-model='isUserConfirm'
      @onSend='
        userConfirmStatus == 1 ? (isCancel = true) : userConfirmStatus == 2 ? (isRefund = true) : (isSelectItem = true)
      '
    />

    <v-dialog v-model='isCancel' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('confirm_cancellation') }}
        </v-card-title>
        <v-form ref='formCancel' @submit.prevent='confirmCancel'>
          <v-card-text>
            <v-textarea
              v-model='comment'
              :label="$t('note_of_cancellation')"
              :rules='[required]'
              outlined
              autofocus
              dense
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='cancelLoading' :disabled='cancelLoading'>
              {{ $t('confirm') }}
            </v-btn>
            <v-btn color='secondary ' outlined @click='isCancel = false'>
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <Refund v-model='isRefund' @onUpdate='getReceipt' />

    <ReceiptOptions v-model='isPrint' :order-id='orderId' :status-path='statusPath' />

    <EditPrice
      v-model='isSelectItem'
      :cartList='cartList'
      @updatePice='getReceipt'
      :discount='receiptData.order_save'
    />

    <PayWithPaySulotion
      v-model='isConfirmPatment'
      :customerData='customerData'
      :orderDetail='receiptData'
      @onAdd='getReceipt'
    />

    <v-dialog v-model='isPaysolutions' persistent max-width='450'>
      <v-card>
        <v-card-title class='text-subtitle-1'>
          {{ $t('payWithPaySulotion') }}
          <v-spacer></v-spacer>
          <span
            class='primary--text text-decoration-underline'>{{ receiptData.order_totalpay | formatPrice
            }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols='12'>
              <v-row align='center' justify='center'>
                <v-col cols='5'>
                  {{ $t('channel_pay') }}
                </v-col>
                <v-col cols='7'>
                  <div style='background-color: #002cc9;font-size: 18px;'
                       class='rounded-lg white--text text-center py-2'>
                    paysolutions
                  </div>
                </v-col>
              </v-row>
              <v-row align='center' justify='center' v-if='!receiptData.coupon_id'>
                <v-col cols='5'>
                  {{ $t('cashback') }}
                </v-col>
                <v-col cols='7'>
                  <v-select
                    v-model='cashbackSelect'
                    :items='cashbackTypeList'
                    dense
                    hide-details
                    outlined
                    item-text='text'
                    item-value='value'
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='5'>
                  {{ $t('user_is_list') }} <br />
                  {{ $t('staff_team') }}
                </v-col>
                <v-col cols='7'>
                  <v-select
                    v-model='commissionSelect'
                    :items='teamsAndUserList'
                    dense
                    hide-details
                    outlined
                    item-text='teams_name'
                    item-value='teams_id'
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='3'>
                  {{ $t('note') }}
                </v-col>
                <v-col cols='9'>
                  <v-textarea
                    v-model='order_comment'
                    :rows='3'
                    hide-details
                    :placeholder="$t('note')"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            :loading='processingDeposit'
            :disabled='processingDeposit'
            @click='paysolutions'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color='secondary'
            outlined
            :loading='processingDeposit'
            :disabled='processingDeposit'
            @click='isPaysolutions = false'
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <UserConfirm v-model="isUserConfirm" @onSend="isSelectItem = true" /> -->
  </div>
</template>

<script>
import { mdiPencilOutline, mdiArrowLeftBold, mdiInformationOutline } from '@mdi/js'
import useOrderDetail from './useOrderDetail'
import EditPrice from './EditPrice.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import PopupCustomer from '@/components/basicComponents/PopupCustomer.vue'
import { formatPrice } from '@/plugins/filters'
import Payment from './Payment.vue'
import PayInstllment from './PayInstllment.vue'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'
import { required } from '@/@core/utils/validation'
import Refund from './Refund.vue'
import { i18n } from '@/plugins/i18n'
import ReceiptOptions from '@/components/prints/ReceiptOptions.vue'
import PayWithPaySulotion from './PayWithPaySulotion.vue'

export default {
  components: {
    PayWithPaySulotion,
    EditPrice,
    ConverseDate,
    PopupCustomer,
    Payment,
    PayInstllment,
    UserConfirm,
    Refund,
    ReceiptOptions,
  },

  filters: {
    formatPrice,
  },

  setup() {
    window.document.title = `${i18n.t('order-detail')} | APSTH Platform Clinic 3.9`

    return {
      required,
      ...useOrderDetail(),
      icons: {
        mdiPencilOutline,
        mdiArrowLeftBold,
        mdiInformationOutline,
      },
    }
  },
}
</script>
