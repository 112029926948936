import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const detailReceiptGet = async id => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'orderlist',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.error('get order detail error ', err)

      return {}
    })

  return response
}

export default { detailReceiptGet }
